import React from 'react'
import "../pages/about.css"
import "../pages/index.css"
import about_pic from "/src/images/about_pic.jpg"
import flor from "/src/images/flores_about.png"
import flor1 from "/src/images/Flores2.png"

import Navigation from "../components/navbar"

function AboutPage() {
  return (
    <>
      <Navigation />
      <title>GABELLA - About</title>
      <div className='about__container'>
        <img src={flor} className="about__flower2" />
        <img src={flor1} className="about__flower1" />
        <div className='about__info'>
          <div className='about__pic'>
            <img src={about_pic} alt="" />
            <h2 className='text__coral m-0'>Antonella</h2>
            <h2 className='text__coral m-0 about__title-span'><span className='text__gold'>&</span> Gabriel</h2>
          </div>
          <div className='about__text'>
            <p className='text__orange m-0 t-up t-m f-400'>We said yes!</p>
            <p className='text__orange m-0 t-up t-m f-400'>Join us in the countdown until we say “I do.”</p>
            <p className='t-m f-400'>Bienvenidos a nuestro wedding website! Como ustedes saben, lo bueno se comparte, por eso queremos celebrar este día tan importante con ustedes. Estamos felices de que nos acompañen en nuestra historia y sean testigos de todo lo bueno que esta por empezar. Aquí podrán encontrar los detalles de la boda y actividades para hacer. Los esperamos de corazón. Gracias por ser parte de este sueño!</p>
          </div>
        </div>
        <div className='about__footer'>
          <p className='text__white t-m'>#GABELLA <spa className='text__pink  t-title'>07.01.23</spa> PANAMÁ</p>
        </div>
      </div>
    </>
  )
}

export default AboutPage
